import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { navigate } from "gatsby";
import * as Ant from "antd";
import { RoleID } from "../Model";
import {
  PlusOutlined,
  TeamOutlined,
  SearchOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import moment from "moment";

import Widget, { Font, Color } from "../Components/Widget";
import SurveyList from "../Components/SurveyList";

import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import { withPage } from "../PageContainer";
import * as Survey from "../Contexts/SurveyContext";
import TemplatesPage from "../Components/MyTemplates/TemplatesPage";
import * as SvgIcon from "../Components/SvgIcon";
import Theme from "../Theme";
import _ from "lodash";

const queryString = require("query-string");

const navbarHeight = 64;

/**
 * 建立新問券Model
 * @param {visible} bool 是否呈現
 * @param {onCancel} func 取消 callback
 * @param {profile} obj 登入用戶物件
 * @param {survey} obj 問卷物件
 * @param {profile} obj 登入用戶物件
 * @return {CreateSurveyModal}
 */
const CreateSurveyModal = ({
  visible,
  onCancel,
  survey,
  profile,
  ...props
}) => {
  const [spin1, setSpin1] = useState(false);
  const [spin2] = useState(false);
  const [showImportTemplateModal, setShowImportTemplateModal] = useState(false);
  const [templateList, setTemplateList] = useState([]);

  // 建立新問卷
  async function createBlankSurvey(profile) {
    try {
      setSpin1(true);
      const _survey = await Survey.Actions.createSurvey(profile, survey, false);
      navigate(`/survey?id=${_survey.id}`);
      onCancel();
    } catch (ex) {
      setSpin1(false);
    }
  }

  useEffect(() => {
    // showImportTemplateModal true 時拉 template
    async function fetchTemplateList() {
      try {
        let list = await Survey.Actions.fetchTemplateList();
        list.data && setTemplateList(list.data);
      } catch (err) {
        setTemplateList([]);
      }
    }

    if (showImportTemplateModal) {
      fetchTemplateList();
    }
  }, [showImportTemplateModal]);

  return (
    <>
      <Ant.Modal
        title="建立新問卷"
        visible={visible}
        footer={null}
        onCancel={onCancel}
      >
        <Widget.FlexRow style={{ justifyContent: "center" }}>
          <Widget.FlexCenter>
            <Widget.FlexCenter
              style={{
                width: 200,
                height: 200,
                border: "1px solid #ccc",
                borderRadius: 10,
                marginBottom: 12,
              }}
              onClick={() => createBlankSurvey(profile)}
            >
              {!spin1 ? (
                <CrossWrapper>
                  <div className="cross-x" />
                  <div className="cross-y" />
                </CrossWrapper>
              ) : (
                <div
                  style={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "fixed",
                    zIndex: "999999",
                  }}
                >
                  <Ant.Spin />
                </div>
              )}
            </Widget.FlexCenter>
            <Font.Body>空白問卷</Font.Body>
          </Widget.FlexCenter>
          <div style={{ width: 20 }} />
          <Widget.FlexCenter>
            <Widget.FlexCenter
              style={{
                width: 200,
                height: 200,
                border: "1px solid #ccc",
                borderRadius: 10,
                marginBottom: 12,
              }}
              onClick={() => setShowImportTemplateModal(true)}
            >
              {!spin2 ? (
                <CrossWrapper>
                  <div className="cross-x" />
                  <div className="cross-y" />
                </CrossWrapper>
              ) : (
                <div
                  style={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "fixed",
                    zIndex: "999999",
                  }}
                >
                  <Ant.Spin />
                </div>
              )}
            </Widget.FlexCenter>
            <Font.Body>我的範本</Font.Body>
          </Widget.FlexCenter>
        </Widget.FlexRow>
      </Ant.Modal>
      <Ant.Modal
        visible={showImportTemplateModal}
        width={1152}
        footer={null}
        onCancel={() => setShowImportTemplateModal(false)}
        closable={false}
        bodyStyle={{ padding: 0 }}
        centered
        style={{ paddingBottom: 0, minWidth: "1152px" }}
        destroyOnClose
        wrapClassName="modal-wrapper"
      >
        <ImportTemplateModalWrapper>
          <div
            className="close"
            onClick={() => setShowImportTemplateModal(false)}
          >
            <SvgIcon.Close></SvgIcon.Close>
          </div>
          <div className="zoom-out" onClick={() => navigate("/templates")}>
            <SvgIcon.openInFullFill></SvgIcon.openInFullFill>
          </div>
          <TemplatesPage
            popup={true}
            profile={profile}
            fromSurvey={survey}
            {...props}
          />
        </ImportTemplateModalWrapper>
      </Ant.Modal>
    </>
  );
};

/**
 * 重新命名團隊名稱
 * @param {visible} bool 是否呈現
 * @param {teamId} string 團隊代碼
 * @param {onCancel} func 取消 callback
 * @param {fetchData} func 父類別抓取資料方法
 * @param {userCode} string 用戶代碼
 * @return {RenameModal}
 * @returns
 */
function RenameModal({
  visible,
  teamId,
  userCode,
  onCancel,
  fetchData,
  ...props
}) {
  const [name, setName] = useState("");

  useEffect(() => {}, []);

  /**
   * 按下【確認】按鈕，觸發事件
   * @returns
   */
  async function submitRename() {
    if (name.length > 50) {
      alert("團隊名稱最大長度為50");
      return;
    }

    try {
      let data = {
        teamId: teamId,
        userCode: userCode,
        name: name,
      };
      let resJSON = await Survey.Actions.submitTeam(data);
      if (resJSON.code && resJSON.code === 200) {
        onCancel();
        fetchData();
      } else {
        alert(resJSON.message);
      }
    } catch (err) {}
  }

  return (
    <>
      <Ant.Modal
        className="custom-modal"
        title={"重新命名"}
        width={480}
        visible={visible}
        onCancel={onCancel}
        okText={"確定"}
        onOk={async () => {
          await submitRename();
        }}
        cancelText={"取消"}
      >
        <div>
          <Ant.Form layout="vertical">
            <div>
              <Ant.Input
                placeholder="輸入新名稱"
                name="name"
                onChange={e => {
                  setName(e.target.value);
                }}
              />
            </div>
          </Ant.Form>
        </div>
      </Ant.Modal>
    </>
  );
}

/**
 * 編輯團隊成員Model
 * @param {visible} bool 是否呈現
 * @param {profile} obj 團隊代碼
 * @param {team} obj 團隊物件
 * @param {userCode} string 用戶代碼
 * @param {teamUsers} array 團隊用戶
 * @param {allUsers} array 所有用戶
 * @param {onCancel} func 取消 callback
 * @param {fetchData} func 父類別抓取資料方法
 * @return {EditMemberModal}
 * @returns
 */
const EditMemberModal = ({
  visible,
  profile,
  team,
  userCode,
  teamUsers,
  allUsers,
  onCancel,
  fetchData,
  ...props
}) => {
  const [inteamUsers, setInTeamUsers] = useState([]);
  const [queryUsers, setQueryUsers] = useState([]);
  const [queryUpdate, setQueryUpdate] = useState(false);
  const [checkedStatus, setCheckedStatus] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [userSelect, setUserSelect] = useState("搜尋姓名、使用者帳號");

  /**
   * 按下【確認】按鈕，觸發事件
   */
  async function submitEditTeamUsers() {
    try {
      let existSelf = false;
      let arrUserData = [];
      let arrTeamUser = inteamUsers;
      arrTeamUser.map(user => {
        let userdata = {
          userId: user.userid,
          roleId: user.teamroleid,
        };
        /**
         * 檢查刪除列表是否有自己，如果有就轉導離開該團隊
         */
        if (user.userid === profile.userId) {
          existSelf = true;
        }
        arrUserData.push(userdata);
      });
      let arrUser = queryUsers;
      arrUser.map(user => {
        let userdata = {
          userId: user.userid,
          roleId: user.teamroleid,
        };
        if (user.userid === profile.userId) {
          existSelf = true;
        }
        arrUserData.push(userdata);
      });
      if (team.OwnerUserId === profile.userId) {
        existSelf = true;
      }

      let data = {
        teamId: team.TeamId,
        userCode: userCode,
        teamUser: arrUserData,
      };
      let resJSON = await Survey.Actions.submitTeamUsers(data);
      if (resJSON.code && resJSON.code === 200) {
        if (team.TeamId !== undefined) {
          /**
           * 如果把自己刪除，會被踢回列表
           */
          if (!existSelf) {
            navigate("/teams");
          } else {
            navigate("/team?id=" + team.TeamId);
          }
        }
        onCancel();
      } else {
        alert(resJSON.message);
      }
    } catch (err) {}
  }

  /**
   * 轉移團隊所有者
   * @param {*} userId
   */
  async function submitTeamOwner(userId) {
    try {
      let data = {
        teamId: team.TeamId,
        userCode: userCode,
        userId: userId,
      };
      let resJSON = await Survey.Actions.submitTeamOwner(data);
      if (resJSON.code && resJSON.code === 200) {
        onCancel();
        fetchData();
      } else {
        alert(resJSON.message);
      }
    } catch (err) {}
  }

  /**
   * 勾選User Check box時觸發
   * @param {*} itemid UserId
   * @param {*} value event
   */
  function userSelectedOnChange(itemid, value) {
    let arrChecked = checkedStatus;
    if (value.target.checked) {
      if (!arrChecked.includes(itemid)) {
        arrChecked.push(itemid);
      }
    } else {
      if (arrChecked.includes(itemid)) {
        arrChecked = arrChecked.filter(item => item !== itemid);
      }
    }
    setCheckedStatus(arrChecked);
    setQueryUpdate(!queryUpdate);
  }

  /**
   * 變更角色下拉選單時觸發
   * @param {*} userId
   * @param {*} value
   */
  function userRoleIdOnChange(userId, value) {
    let arrTeamUser = inteamUsers;
    arrTeamUser.map(user => {
      if (user.userid === userId) {
        user.teamroleid = value;
      }
    });
    setInTeamUsers(arrTeamUser);
    let arrUser = queryUsers;
    arrUser.map(user => {
      if (user.userid === userId) {
        user.teamroleid = value;
      }
    });
    setQueryUsers(arrUser);
    setQueryUpdate(!queryUpdate);
  }

  /**
   * 左下角【刪除成員】按鈕，觸發事件
   */
  function removeUserSelected() {
    let arrTeamUser = inteamUsers;
    let arrUser = queryUsers;
    let arrChecked = checkedStatus;
    arrChecked.map(userId => {
      let contain = false;
      arrTeamUser.map(user => {
        if (user.userid === userId) {
          contain = true;
        }
      });
      if (contain) {
        arrTeamUser = arrTeamUser.filter(item => item.userid !== userId);
      }
    });

    arrChecked.map(userId => {
      let contain = false;
      arrUser.map(user => {
        if (user.userid === userId) {
          contain = true;
        }
      });
      if (contain) {
        arrUser = arrUser.filter(item => item.userid !== userId);
      }
    });
    setInTeamUsers(arrTeamUser);
    setQueryUsers(arrUser);
    setCheckedStatus([]);
    setQueryUpdate(!queryUpdate);
  }

  /**
   * 檢查UserId是否在queryUsers
   * @param {*} userId
   * @returns
   */
  function checkUserInQuery(userId) {
    let result = false;
    let arrUser = queryUsers;
    arrUser.map(user => {
      if (user.userid === userId) {
        result = true;
      }
    });
    return result;
  }

  /**
   * 檢查UserId是否在團隊裡
   * @param {*} userId
   * @returns
   */
  function checkUserInTeam(userId) {
    let result = false;
    let arrUser = inteamUsers;
    arrUser.map(user => {
      if (user.userid === userId) {
        result = true;
      }
    });
    return result;
  }

  /**
   * 轉移擁有者彈跳視窗
   * @param {*} item User
   */
  function changeTeamOwner(item) {
    Ant.Modal.confirm({
      title: "確認要轉移團隊擁有者嗎？",
      content: "",
      okText: "取消",
      okType: "primary",
      onOk: () => 0,
      cancelButtonProps: { type: "ghost" },
      cancelText: "確認轉移",
      onCancel: async () => {
        submitTeamOwner(item.userid);
      },
      icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
    });
  }

  useEffect(() => {
    setInTeamUsers(teamUsers);
    var name = String(team.TeamName);

    /**
     * 團隊名稱超過25個字，後面的字以 "..." 取代
     */
    setModalTitle(
      "新增團隊成員至 " +
        (name.length > 25 ? name.substring(0, 25) + "..." : name)
    );
  }, [teamUsers]);

  return (
    <Ant.Modal
      className="custom-modal"
      title={
        <div
          style={{
            overflow: "hidden",
            height: 24,
          }}
        >
          {modalTitle}
        </div>
      }
      width={480}
      visible={visible}
      bodyStyle={{ padding: "16px 24px 40px" }}
      onCancel={() => {
        setQueryUsers([]);
        setCheckedStatus([]);
        setQueryUpdate(!queryUpdate);
        onCancel();
      }}
      centered
      okText="確定"
      onOk={async () => {
        await submitEditTeamUsers();
      }}
      cancelText="取消"
    >
      <EditMemberModalWrapper>
        <Ant.Form layout="vertical">
          <div className="title">
            新增的成員可依據個別權限編輯或檢視團隊中問卷
          </div>
          <Ant.Select
            className="user-list-select"
            showArrow
            showSearch
            suffixIcon={<SearchOutlined />}
            placeholder="搜尋姓名、使用者帳號"
            optionFilterProp="children"
            optionLabelProp="label"
            value={userSelect}
            defaultValue={userSelect}
            filterOption={(input, option) => {
              return (
                option.label.toLowerCase().includes(input.toLowerCase()) ||
                option.code.toLowerCase().includes(input.toLowerCase())
              );
            }}
            onChange={value => {
              setUserSelect(value);
              let arrUser = queryUsers;
              let contain = false;
              arrUser.map(user => {
                if (user.id === value) {
                  contain = true;
                }
              });
              allUsers.map(user => {
                if (
                  user.userid === value &&
                  !contain &&
                  user.userid !== team.OwnerUserId
                ) {
                  user.teamroleid = 103;
                  arrUser.push(user);
                }
              });
              setQueryUsers(arrUser);
              setQueryUpdate(!queryUpdate);
              setUserSelect("搜尋姓名、使用者帳號");
            }}
            disabled={
              profile &&
              profile.userId !== team.OwnerUserId &&
              profile.RoleId !== 1
            }
            style={{
              backgroundColor:
                profile &&
                profile.userId !== team.OwnerUserId &&
                profile.RoleId !== 1
                  ? Color.Gray_05
                  : "",
            }}
          >
            {allUsers?.map((item, idx) => {
              return (
                <Ant.Select.Option
                  key={idx}
                  value={item.userid}
                  label={item.name}
                  code={item.UserCode ?? ""}
                  disabled={
                    item.name === team.OwnerUserName ||
                    checkUserInQuery(item.userid) ||
                    checkUserInTeam(item.userid)
                  }
                  style={{
                    backgroundColor:
                      item.name === team.OwnerUserName ||
                      checkUserInQuery(item.userid) ||
                      checkUserInTeam(item.userid)
                        ? Color.Selected_Blue
                        : "",
                  }}
                >
                  <EditMemberModalSelectOptionWrapper>
                    <Ant.Row>
                      <Ant.Col className="name" span={8}>
                        {item.name}
                      </Ant.Col>
                      <Ant.Col className="user-name" span={8}>
                        {item.username}
                      </Ant.Col>
                      {/* <Ant.Col
                        span={4}
                        style={{
                          textAlign: "right",
                          fontSize: 10,
                          paddingLeft: 50,
                        }}
                      >
                        {moment(item.updated).format("YYYY/MM/DD HH:mm:ss")}
                      </Ant.Col> */}
                    </Ant.Row>
                  </EditMemberModalSelectOptionWrapper>
                </Ant.Select.Option>
              );
            })}
          </Ant.Select>
          <Ant.Divider style={{ margin: "0px 0px 9px" }} />
          <div className="row-owner">
            <div></div>
            <div>{team.OwnerUserName}</div>
            <div>{team.OwnerUserCode}</div>
            <div>擁有者</div>
          </div>
          {inteamUsers?.map((item, idx) => {
            return (
              <div key={idx} className="row-query-user">
                <div>
                  <Ant.Checkbox
                    className="user-checkbox"
                    checked={checkedStatus.includes(item.userid)}
                    onChange={value => userSelectedOnChange(item.userid, value)}
                    disabled={
                      profile &&
                      profile.userId !== team.OwnerUserId &&
                      profile.RoleId !== 1
                    }
                  />
                </div>
                <div>{item.name}</div>
                <div>{item.username}</div>
                <div>
                  <Ant.Select
                    disabled={
                      profile &&
                      profile.userId !== team.OwnerUserId &&
                      profile.RoleId !== 1
                    }
                    style={{
                      backgroundColor:
                        profile &&
                        profile.userId !== team.OwnerUserId &&
                        profile.RoleId !== 1
                          ? Color.Gray_05
                          : "",
                    }}
                    className="permission-select"
                    defaultValue={item.teamroleid ? item.teamroleid : 103}
                    onChange={value => userRoleIdOnChange(item.userid, value)}
                    dropdownRender={menu => (
                      <>
                        {menu}
                        <Ant.Divider
                          style={{
                            margin: "5px 0px",
                            backgroundColor: "#979797",
                          }}
                        />
                        <div
                          style={{
                            width: "100%",
                            padding: "3px 0 3px 17px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (
                              profile &&
                              (profile.RoleId < 2 ||
                                profile.userId === team.OwnerUserId)
                            ) {
                              changeTeamOwner(item);
                            } else {
                              alert("權限不足，無法操作");
                            }
                          }}
                        >
                          轉移擁有者
                        </div>
                      </>
                    )}
                  >
                    <Ant.Select.Option value={102}>編輯者</Ant.Select.Option>
                    <Ant.Select.Option value={103}>檢視者</Ant.Select.Option>
                  </Ant.Select>
                </div>
              </div>
            );
          })}
          {queryUsers?.map((item, idx) => {
            return (
              <div key={idx} className="row-query-user">
                <div>
                  <Ant.Checkbox
                    className="user-checkbox"
                    checked={checkedStatus.includes(item.userid)}
                    onChange={value => userSelectedOnChange(item.userid, value)}
                    disabled={
                      profile &&
                      profile.userId !== team.OwnerUserId &&
                      profile.RoleId !== 1
                    }
                  />
                </div>
                <div>{item.name}</div>
                <div>{item.username}</div>
                <div>
                  <Ant.Select
                    disabled={
                      profile &&
                      profile.userId !== team.OwnerUserId &&
                      profile.RoleId !== 1
                    }
                    style={{
                      backgroundColor:
                        profile &&
                        profile.userId !== team.OwnerUserId &&
                        profile.RoleId !== 1
                          ? Color.Gray_05
                          : "",
                    }}
                    className="permission-select"
                    defaultValue={item.teamroleid ? item.teamroleid : 103}
                    onChange={value => userRoleIdOnChange(item.userid, value)}
                    dropdownRender={menu => (
                      <>
                        {menu}
                        <Ant.Divider
                          style={{
                            margin: "5px 0px",
                            backgroundColor: "#979797",
                          }}
                        />
                        <div
                          style={{
                            width: "100%",
                            padding: "3px 0 3px 17px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (
                              profile &&
                              (profile.RoleId < 2 ||
                                profile.userId === team.OwnerUserId)
                            ) {
                              changeTeamOwner(item);
                            } else {
                              alert("權限不足，無法操作");
                            }
                          }}
                        >
                          轉移擁有者
                        </div>
                      </>
                    )}
                  >
                    <Ant.Select.Option value={102}>編輯者</Ant.Select.Option>
                    <Ant.Select.Option value={103}>檢視者</Ant.Select.Option>
                  </Ant.Select>
                </div>
              </div>
            );
          })}
          {checkedStatus.length > 0 && (
            <div>
              <Ant.Button
                className="delete-member-btn"
                type="text"
                onClick={() => {
                  if (
                    profile &&
                    (profile.RoleId < 2 || profile.userId === team.OwnerUserId)
                  ) {
                    removeUserSelected();
                  } else {
                    alert("權限不足，無法操作");
                  }
                }}
              >
                刪除成員
              </Ant.Button>
            </div>
          )}
        </Ant.Form>
      </EditMemberModalWrapper>
    </Ant.Modal>
  );
};

const EditMemberModalWrapper = styled.div`
  .title {
    font-size: 14px;
    color: ${Color.GreyBlack_65};
    margin-bottom: 16px;
  }

  .user-list-select {
    width: 100%;
    margin-bottom: 24px;

    .ant-select-selector {
      min-height: 40px;

      .ant-select-selection-search-input {
        height: 100% !important;
      }

      .ant-select-selection-placeholder {
        display: flex;
        align-items: center;
        font-size: 16px;
      }
    }
  }

  .row-owner,
  .row-query-user {
    display: flex;
    align-items: center;

    > div:nth-child(1) {
      flex-basis: 16px;
      margin-right: 16px;
    }

    > div:nth-child(2) {
      flex-basis: 100px;
      margin-right: 20px;
      font-size: 16px;
      color: ${Color.GreyBlack_65};
    }

    > div:nth-child(3) {
      flex-basis: 120px;
      margin-right: 25px;
      font-size: 14px;
      color: ${Color.GreyBlack_45};
    }

    > div:nth-child(4) {
      flex-basis: 135px;
    }
  }

  .row-owner {
    > div:nth-child(4) {
      font-size: 16px;
      color: ${Color.GreyBlack_45};
    }
  }

  .row-query-user {
    margin-top: 16px;
  }

  .permission-select {
    width: 100%;

    .ant-select-arrow {
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .ant-select-arrow {
    width: 17px;
    height: 17px;
    top: 43%;
    color: ${Color.GreyBlack_45};

    svg {
      width: 17px;
      height: 17px;
    }
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .delete-member-btn {
    width: 88px;
    height: 32px;
    padding: 5px 16px;
    border: 1px solid rgba(83, 74, 179, 0.85);
    color: ${Color.Purple};
    margin-top: 16px;
  }
`;

const EditMemberModalSelectOptionWrapper = styled.div`
  .name {
    font-size: 14px;
    color: ${Color.GreyBlack_65};
    padding-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-name {
    font-size: 14px;
    color: ${Color.Purple};
    opacity: 0.85;
  }
`;

/**
 * 新增問券至團隊
 * @param {*} param0
 * @returns
 */
const ImportModal = ({
  visible,
  team,
  userCode,
  teamSurveys,
  allSurveys,
  onCancel,
  fetchData,
  ...props
}) => {
  const [addSurveys, setAddSurveys] = useState([]); // 新加入的問卷id Array
  const [existSurveys, setExistSurveys] = useState([]); // 已經被加入的 問卷 id Arr
  const [selectValue, setSelectValue] = useState([]); // 下拉選單value

  const [loading, setLoading] = useState({ confirm: false });

  /**
   * 按下【確認】按鈕，觸發事件
   */
  async function submitImport() {
    try {
      setLoading(state => ({ ...state, confirm: true }));
      if (addSurveys.length > 0) {
        let data = {
          teamId: team.TeamId,
          userCode: userCode,
          surveys: [...existSurveys, ...addSurveys],
          // 由於匯入一次是整個存，所以要先把 已經存在的問卷id + 新加入的問卷id 一起放入
        };
        let resJSON = await Survey.Actions.submitTeamSurveys(data);
        setLoading(state => ({ ...state, confirm: false }));
        if (resJSON.code && resJSON.code === 200) {
          // 關閉Model
          onCancel();
          // 重新讀表列表
          fetchData();
        } else {
          alert(resJSON.message);
        }
      }
    } catch (err) {}
  }

  // 一進來先整理現有的文卷存成一個idArray
  useEffect(() => {
    const arrSurvey = teamSurveys.map(team => {
      return team.id;
    });
    setExistSurveys(arrSurvey);
  }, [teamSurveys]);

  return (
    <Ant.Modal
      className="custom-modal"
      title="選擇要加入的問卷"
      width={600}
      visible={visible}
      cancelText="取消"
      centered
      onCancel={() => {
        setSelectValue([]);
        if (onCancel) onCancel();
      }}
      okText="確定"
      confirmLoading={loading.confirm}
      onOk={async () => {
        await submitImport();
      }}
    >
      <ImportModalWrapper>
        <Widget.FlexRow style={{ marginBottom: 16 }}>
          <Font.Head style={{ color: Color.GreyBlack, marginRight: 16 }}>
            將問卷
          </Font.Head>
          <Ant.Select
            mode="multiple"
            style={{ flex: 1 }}
            placeholder="問卷名稱"
            optionFilterProp="children"
            optionLabelProp="label"
            showArrow
            suffixIcon={<SearchOutlined />}
            value={selectValue}
            filterOption={(input, option) => option.label.includes(input)}
            menuItemSelectedIcon={false}
            onChange={value => {
              let arrSurvey = [];
              value.map(v => {
                if (!existSurveys.includes(v)) {
                  arrSurvey.push(v);
                }
              });
              setAddSurveys(arrSurvey);
              setSelectValue(value);
            }}
          >
            {allSurveys?.map((item, idx) => {
              // 下拉選單只呈現 未被加入的問卷
              if (!existSurveys.includes(item.id) && item.teamId === "") {
                return (
                  <Ant.Select.Option
                    key={idx}
                    value={item.id}
                    label={item.title}
                  >
                    <ImportModalSelectOptionWrapper>
                      <Ant.Row>
                        <Ant.Col className="questionnaire_name" span={11}>
                          {item.title}
                        </Ant.Col>
                        <Ant.Col className="questionnaire_author" span={6}>
                          {item.author}
                        </Ant.Col>
                        <Ant.Col className="questionnaire_date" span={7}>
                          {moment(item.modified).format("YYYY/MM/DD HH:mm:ss")}
                        </Ant.Col>
                      </Ant.Row>
                    </ImportModalSelectOptionWrapper>
                  </Ant.Select.Option>
                );
              }
            })}
          </Ant.Select>
        </Widget.FlexRow>
        <Widget.FlexRow>
          <Font.Head style={{ color: Color.GreyBlack, marginRight: 8 }}>
            匯入至
          </Font.Head>
          <Font.Head style={{ color: Color.Purple, marginRight: 8 }}>
            {team.TeamName}
          </Font.Head>
          <Font.Head style={{ color: Color.GreyBlack }}>團隊中</Font.Head>
        </Widget.FlexRow>
      </ImportModalWrapper>
    </Ant.Modal>
  );
};

const ImportModalWrapper = styled.div`
  .ant-select-selector {
    min-height: 40px;
  }

  .ant-select-selection-item {
    border-radius: 12px;
    background-color: #edecf7;
  }
`;

const ImportModalSelectOptionWrapper = styled.div`
  .questionnaire_name {
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: ${Color.GreyBlack_65};
  }

  .questionnaire_author {
    padding-right: 20px;
    font-size: 14px;
    color: ${Color.Purple};
  }

  .questionnaire_date {
    font-size: 14px;
    color: ${Color.GreyBlack_45};
  }
`;

/**
 * 團隊列表主頁面邏輯
 * @param {*} param0
 * @returns
 */
function TeamDetailPage({ profile, ...props }) {
  const { appActions } = props;
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState({});
  const [surveys, setSurveys] = useState([]);
  const [allSurveys, setAllSurveys] = useState([]);
  const [surveyStatusList, setSurveyStatusList] = useState([]);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showEditMemberModal, setShowEditMemberModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [userCode, setUserCode] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectSurvey, setSelectSurvey] = useState(null);

  const [filters, setFilters] = useState({
    userName: [],
  });
  const [search, setSearch] = useState(null);
  const [pageInfo, setPageInfo] = useState({ current: 1, total: 0 });
  const [queryInfo, setQueryInfo] = useState({});
  const [page, setPage] = useState(1);
  const [originTeamId, setOriginTeamId] = useState(null);
  const [reset, setReset] = useState(false);
  const perPage = 10;

  let windowGlobal = typeof window !== "undefined" && window;

  useEffect(() => {
    windowGlobal && windowGlobal.localStorage.getItem("token");
    let token = windowGlobal.localStorage.getItem("token");

    if (profile && token) {
      fetchData();
    }

    if (!profile || !token) {
      appActions.logout();
      console.debug("各路工程師想要使用者回老家");
      navigate("/");
    } else if (profile && profile.RoleId > 1) {
      // 只有管理員才能夠編輯
      //navigate("/");
    }
    /**
     * 新增團隊時才會觸發
     * 網址query帶入t=1的時候，編輯團隊成員Model會先打開
     */
    const t = queryString.parse(props.location.search).t;
    if (t === "1") {
      setShowEditMemberModal(true);
    }
  }, [props.location]);
  useEffect(() => {
    fetchSurvey();
  }, [page, queryInfo, search]);

  const teamId = queryString.parse(props.location.search).id;
  useEffect(() => {
    setReset(false);
  }, [reset]);
  if (originTeamId !== teamId) {
    setPage(1);
    setQueryInfo({});
    setSearch(null);
    setOriginTeamId(teamId);
    setReset(true);
  }

  async function fetchSurvey() {
    setLoading(true);
    const teamSurveys = await Survey.Actions.fetchTeamSurveys(
      profile,
      teamId,
      page,
      perPage,
      queryInfo,
      search
    );
    setSurveys(teamSurveys.data); // 取得問卷列表
    setFilters(teamSurveys.filters);
    setPageInfo(teamSurveys.pagination);
    setLoading(false);
  }
  /**
   * 刷新列表頁面
   */
  async function fetchData() {
    setLoading(true);
    try {
      setUserCode(profile.UserCode);

      const teamId = queryString.parse(props.location.search).id;
      setTeam(await Survey.Actions.queryTeamById(teamId));

      const teamSurveys = await Survey.Actions.fetchTeamSurveys(
        profile,
        teamId,
        page,
        perPage,
        queryInfo,
        search
      );
      setSurveys(teamSurveys.data); // 取得問卷列表
      setFilters(teamSurveys.filters);
      setPageInfo(teamSurveys.pagination);

      const surveys = await Survey.Actions.fetchMySurveys(profile, 0, 9999);
      setAllSurveys(surveys.data); // 取得所有問卷
      setSurveyStatusList(await Survey.Actions.fetchSurveyStatus()); // 取得問卷狀態列表

      setUsers(await Survey.Actions.fetchTeamUsers(teamId)); // 取得團隊users
      const users = await Survey.Actions.fetchAccountList(profile, 0, 9999);
      setAllUsers(users.data); // 取得所有user

      // if (profile.userId === team.OwnerUserId || profile.RoleId === 1) {
      //   setCanEdit(true);
      // } else {
      //   let arrUser = users;
      //   arrUser.map(user => {
      //     if (profile.userId === user.UserId) {
      //       setCanEdit(true);
      //     }
      //   });
      // }
    } catch (ex) {
      setLoading(false);
      throw ex;
    }
    setLoading(false);
  }

  /**
   * 刪除團隊
   * @param {*} team
   */
  function removeTeam(team) {
    Ant.Modal.confirm({
      title: "確認要刪除？",
      content: "",
      okText: "取消",
      okType: "primary",
      onOk: () => 0,
      cancelButtonProps: {
        type: "ghost",
        style: { border: `1px solid ${Color.Purple}`, color: Color.Purple },
      },
      cancelText: "確認刪除",
      onCancel: async () => {
        const hide = Ant.message.loading("刪除中...", 0);
        try {
          await Survey.Actions.deleteTeam(team.TeamId);
          props.setUpdater(props.updater + 1);
        } catch (ex) {}
        hide();
        window.location.replace("/");
      },
      icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
    });
  }

  /** 是否可新增問卷至團隊 */
  const canAddSurveyToTeam = useMemo(() => {
    if (!profile) return false;
    if (!team) return false;
    /** 系統管理員可以加入問卷 */

    if (profile.RoleId === RoleID.SystemAdmin) return true;
    /** 團隊擁有者可以加入問卷 */

    if (profile.userId === team.OwnerUserId) return true;
    /** 團隊中的編輯者可以加入問卷 */
    return !!_.find(
      users,
      u =>
        u.UserId === profile.userId &&
        (u.RoleId === RoleID.TeamOwner || u.RoleId === RoleID.TeamEditor)
    );
  }, [team, users, profile]);

  return (
    // !loading &&
    team &&
    surveys &&
    allSurveys &&
    surveyStatusList &&
    users &&
    allUsers && (
      <Wrapper>
        <div className="content">
          <Font.LargeTitle style={{ marginBottom: 20 }}>
            <Ant.Popover
              placement="bottomLeft"
              content={
                <div
                  style={{ minWidth: 122, marginLeft: -16, marginRight: -16 }}
                >
                  <div
                    className="hover-mask"
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      paddingLeft: 16,
                      paddingRight: 16,
                    }}
                    onClick={() => {
                      if (
                        profile.userId === team.OwnerUserId ||
                        profile.RoleId === 1
                      ) {
                        setShowRenameModal(true);
                      }
                    }}
                  >
                    重新命名
                  </div>
                  <div
                    className="hover-mask"
                    style={{
                      paddingTop: 4,
                      paddingBottom: 4,
                      paddingLeft: 16,
                      paddingRight: 16,
                    }}
                    onClick={() => {
                      if (
                        profile.userId === team.OwnerUserId ||
                        profile.RoleId === 1
                      ) {
                        removeTeam(team);
                      }
                    }}
                  >
                    刪除團隊
                  </div>
                </div>
              }
              trigger="hover"
            >
              {team.TeamName}
            </Ant.Popover>
            <TeamOutlined
              style={{ paddingLeft: 10 }}
              onClick={() => setShowEditMemberModal(true)}
            />
            {surveys.length > 0 && (
              <Ant.Input.Search
                placeholder="名稱..."
                onSearch={value => {
                  setSearchName(value);
                }}
                style={{
                  width: 162,
                  float: "right",
                }}
              />
            )}
          </Font.LargeTitle>
          {surveys.length > 0 ? (
            <div>
              <Ant.Button
                icon={<PlusOutlined size={14} />}
                style={{ height: 40, minWidth: 116, marginBottom: 36 }}
                onClick={() => {
                  setShowImportModal(true);
                }}
                disabled={canAddSurveyToTeam !== true}
              >
                新增問卷至團隊
              </Ant.Button>
              <SurveyList
                surveys={surveys}
                filters={filters}
                pageInfo={pageInfo}
                perPage={perPage}
                onPageChange={page => {
                  setPage(page);
                }}
                onTableChange={(selectedRowKeys, selectedRows, info) => {
                  setPage(1);
                  setQueryInfo(selectedRows);
                }}
                onSearch={keyword => {
                  setPage(1);
                  setSearch(keyword);
                }}
                reset={reset}
                showSearch={true}
                searchName={searchName}
                surveyStatusList={surveyStatusList}
                loading={loading}
                openShowCreateModal={survey => {
                  setShowCreateModal(true);
                  setSelectSurvey(survey);
                }}
              />
              <CreateSurveyModal
                visible={showCreateModal}
                survey={selectSurvey}
                surveyTwo={selectSurvey}
                onCancel={() => {
                  setShowCreateModal(false);
                  setSelectSurvey(null);
                }}
                profile={profile}
              />
            </div>
          ) : (
            <div>
              <Ant.Row gutter={[0, 16]} justify="center">
                <Ant.Col span={20} style={{ textAlign: "center" }}>
                  <img
                    src={require("../images/empty-team.png")}
                    width="230"
                    alt=""
                  />
                </Ant.Col>
                <Ant.Col span={20} style={{ textAlign: "center" }}>
                  <h2 style={{ color: Theme.colorDark }}>目前沒有問卷</h2>
                </Ant.Col>
                <Ant.Col span={20} style={{ textAlign: "center" }}>
                  <Ant.Button
                    type="primary"
                    icon={<PlusOutlined size={14} />}
                    style={{ height: 40, minWidth: 116 }}
                    onClick={() => {
                      setShowImportModal(true);
                    }}
                    disabled={
                      profile &&
                      !(
                        profile.userId === team.OwnerUserId ||
                        profile.RoleId === 1
                      )
                    }
                  >
                    新增問卷至團隊內
                  </Ant.Button>
                </Ant.Col>
              </Ant.Row>
            </div>
          )}
          {/* 重新命名 Modal */}
          <RenameModal
            fetchData={fetchData}
            visible={showRenameModal}
            teamId={team.TeamId}
            userCode={userCode}
            onCancel={() => {
              setShowRenameModal(false);
            }}
          />
          {/* 編輯成員 Modal */}
          <EditMemberModal
            fetchData={fetchData}
            visible={showEditMemberModal}
            profile={profile}
            team={team}
            userCode={userCode}
            teamUsers={users}
            allUsers={allUsers}
            onCancel={() => {
              setShowEditMemberModal(false);
            }}
          />
          {/* 匯入成員  */}
          <ImportModal
            fetchData={fetchData}
            visible={showImportModal}
            team={team}
            userCode={userCode}
            teamSurveys={surveys}
            allSurveys={allSurveys}
            onCancel={() => {
              setShowImportModal(false);
            }}
          />
        </div>
      </Wrapper>
    )
  );
}

const Wrapper = styled.div`
  background-color: #fafafa;
  min-height: calc(100vh - 64px);

  & > .content {
    width: 1440px;
    height: calc(100vh - ${navbarHeight}px);
    padding: 40px 36px 34px;
    overflow-y: scroll;

    @media screen and (min-width: 1440px) {
      width: 100%;
    }
  }

  .title-wrapper {
    display: none;
  }
`;

const CrossWrapper = styled.div`
  position: relative;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  & .cross-x {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #979797;
  }

  & .cross-y {
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: #979797;
  }
`;

const ImportTemplateModalWrapper = styled.div`
  .close {
    position: absolute;
    top: -15px;
    right: -15px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .zoom-out {
    position: absolute;
    top: 47px;
    right: 30px;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  .template-wrapper {
    height: 90vh;
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
    }),
    ActionCreator
  )(TeamDetailPage)
);
